<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="5"> </van-col>
        <van-col span="14" style="text-align: center"
          ><span class="sel1">推荐</span
          ><span class="nor1" @click="party">活动</span>
          <span class="nor2" @click="search">搜索</span></van-col
        >
        <van-col span="5"></van-col>
      </van-row>
      <van-row class="toprow">
        <van-col span="6" justify="center" align="center" @click="sxb"
          >性别
          <van-icon name="arrow-down" size="15" />
        </van-col>
        <van-col span="6" justify="center" align="center" @click="snl"
          >年龄
          <van-icon name="arrow-down" size="15" />
        </van-col>
        <van-col span="6" justify="center" align="center" @click="ssg"
          >身高
          <van-icon name="arrow-down" size="15" />
        </van-col>
        <van-col span="6" justify="center" align="center" @click="sall"
          >更多
          <van-icon name="arrow-down" size="15" />
        </van-col>
      </van-row>
    </van-sticky>

    <div ref="target"></div>
    <!--<van-sticky>
      <van-row class="topbak">
        <van-col span="6"></van-col>
        <van-col span="12" style="text-align: center"
          ><span class="sel1">推荐</span><span class="nor1">活动</span>
          <span class="nor1">搜索</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>-->
    <!-- <Topnav /> -->

    <!--<div class="top3">
      <van-row class="topbak2">
        <van-col span="8" class="zhid">置顶推荐</van-col>
        <van-col span="16" class="shenq">也想展示在这里？戳我 ></van-col>
      </van-row>
      <van-row>
        <ul class="myul">
          <li class="myli" v-for="(item, index) in zdlist" :key="index" @click="clicktop(index)" >
            <van-image
              fit="cover"
              radius="5"
              class="myimg2"
              width="100"
              height="120"
              :src="item.imglist"
            />
            <div
              style="
                text-align: center;
                font-size: 16px;
                margin: 5px 0px 3px 0px;
              "
            >
              {{ item.name }}
            </div>
            <div
              style="
                text-align: center;
                font-size: 12px;
                color: RGB(120, 120, 120);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ item.xg }} {{ item.age }}岁 {{ item.sg }} {{ item.zw }}
            </div>
          </li>
        </ul>
      </van-row>
    </div>
    <div class="mybn2">推荐会员</div>
    <div class="sqzd">你的另一半已上线，还在等什么？</div>
    <div class="mybn">
      <div :class="clicksel == '0' ? 'sel' : 'nor'" @click="clicksel0">
        魅力榜
      </div>
      <div :class="clicksel == '2' ? 'sel' : 'nor'" @click="clicksel2">
        优质女会员
      </div>
      <div :class="clicksel == '1' ? 'sel' : 'nor'" @click="clicksel1">
        优质男会员
      </div>
    </div>-->
    <div style="margin-top: 10px; height: 100vh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-grid
          :border="false"
          :column-num="2"
          :gutter="16"
          style="background-color: white"
          ><!--原来是4-->
          <van-grid-item v-for="(item, index) in mmlist" :key="index">
            <div
              style="
                border: 1px solid rgb(242, 242, 242);
                width: 100%;
                border-radius: 5px;
              "
            >
              <!--grid宫格没有圆角，为了显示圆角，专门加了一层div-->
              <div @click="clickme(index)" style="width: 100%">
                <div class="zhan">{{ item.name }}</div>
                <van-image
                  radius="5"
                  :src="item.imglist"
                  height="230"
                  width="100%"
                  fit="cover"
                />
                <div class="con1">
                  <van-image
                    class="myimg"
                    width="12"
                    height="12"
                    :src="
                      item.xb == '0'
                        ? require('../assets/boy.png')
                        : require('../assets/girl.png')
                    "
                  />
                  {{ item.adress }}･{{ item.age }}岁･{{ item.sg }}CM
                </div>
                <div class="con2">
                  <div class="myxl">{{ item.sr }}</div>
                  <div class="myxl">{{ item.zw }}</div>
                  <!--<van-image
                    v-show="item.car != '无车'"
                    class="myimg2"
                    width="15"
                    height="15"
                    :src="require('../assets/xc.png')"
                  />
                  <van-image
                    class="myimg2"
                    v-show="item.house != '无房'"
                    width="15"
                    height="15"
                    :src="require('../assets/fz.png')"
                  />
                  <van-image
                    class="myimg3"
                    width="15"
                    height="15"
                    :src="require('../assets/flower.png')"
                  />0-->
                </div>
              </div>
            </div>
          </van-grid-item>
        </van-grid>
      </van-list>

      <div
        style="
          height: 90px;
          font-size: 12px;
          color: rgb(220, 220, 220);
          text-align: center;
          margin-top: 10px;
        "
      >
        中绘信息科技提供技术支持 V1.0
      </div>
    </div>

    <div class="concat" @click="concat">
      <van-image
        width="21px"
        height="21px"
        :src="require('../assets/hn.png')"
      />红娘
    </div>
    <div class="totop" @click="totop">
      <van-image
        width="21px"
        height="18px"
        :src="require('../assets/totop.png')"
      />顶部
    </div>

    <van-overlay :show="show" @click="show = false" style="z-index: 99999">
      <div class="wrapper">
        <div class="block" @click.stop>
          <van-image
            width="160px"
            height="160px"
            :src="vxcode"
          />

          <div class="tiptxt">长按二维码添加红娘微信</div>
          <div class="tiptxt2">开通会员，飞速脱单</div>
        </div>
        <van-image
          style="margin-top: 15px"
          width="40px"
          height="40px"
          :src="require('../assets/close.png')"
        />
      </div>
    </van-overlay>

    <van-popup
      style="z-index: 9999; height: 280px"
      v-model:show="show2"
      round
      closeable
      position="bottom"
    >
      <div class="f1">性别筛选</div>
      <van-grid
        :border="false"
        :column-num="2"
        :gutter="6"
        style="background-color: white; font-size: 12px"
      >
        <van-grid-item @click="clickxb(0)">
          <van-image
            :class="xb == '0' ? 'myimg4' : 'myimg5'"
            width="90"
            height="90"
            :src="require('../assets/gg.jpg')"
          />
          <van-image
            :class="xb == '0' ? 'show' : 'noshow'"
            width="16"
            height="16"
            :src="require('../assets/gou.png')"
          />
          <!--<div>男</div>-->
        </van-grid-item>
        <van-grid-item @click="clickxb(1)">
          <van-image
            :class="xb == '1' ? 'myimg4' : 'myimg5'"
            width="90"
            height="90"
            :src="require('../assets/mm.jpg')"
          />
          <van-image
            :class="xb == '1' ? 'show' : 'noshow'"
            width="16"
            height="16"
            :src="require('../assets/gou.png')"
          />
        </van-grid-item>
      </van-grid>
      <div style="width: 100%; text-align: center; margin-top: 15px">
        <van-button
          @click="searchbykey"
          round
          style="
            width: 250px;
            height: 40px;
            background-color: #ec616a;
            line-height: 30px;
            color: white;
          "
          >立即筛选</van-button
        >
      </div>
    </van-popup>

    <van-popup
      style="
        z-index: 9999;
        height: 280px;
        padding: 0px 30px 0px 30px;
        box-sizing: border-box;
      "
      v-model:show="show3"
      round
      closeable
      position="bottom"
    >
      <div class="f1">年龄筛选</div>
      <div
        style="
          margin-bottom: 10px;
          font-size: 18px;
          color: #ec616a;
          text-align: center;
        "
      >
        {{ nl[0] }}-{{ nl[1] }}岁
      </div>
      <van-slider v-model="nl" range min="18" max="68" active-color="#ec616a" />
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px 40px 0px;
        "
      >
        <div>18</div>
        <div>28</div>
        <div>38</div>
        <div>48</div>
        <div>58</div>
        <div>68</div>
      </div>

      <div style="width: 100%; text-align: center; margin-top: 15px">
        <van-button
          @click="searchbykey"
          round
          style="
            width: 250px;
            height: 40px;
            background-color: #ec616a;
            line-height: 30px;
            color: white;
          "
          >立即筛选</van-button
        >
      </div>
    </van-popup>

    <van-popup
      style="
        z-index: 9999;
        height: 280px;
        padding: 0px 30px 0px 30px;
        box-sizing: border-box;
      "
      v-model:show="show4"
      round
      closeable
      position="bottom"
    >
      <div class="f1">身高筛选</div>
      <div
        style="
          margin-bottom: 10px;
          font-size: 18px;
          color: #ec616a;
          text-align: center;
        "
      >
        {{ sg[0] }}-{{ sg[1] }}CM
      </div>
      <van-slider
        v-model="sg"
        range
        min="100"
        max="200"
        active-color="#ec616a"
      />
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px 40px 0px;
        "
      >
        <div>100</div>
        <div>120</div>
        <div>140</div>
        <div>160</div>
        <div>180</div>
        <div>200</div>
      </div>

      <div style="width: 100%; text-align: center; margin-top: 15px">
        <van-button
          @click="searchbykey"
          round
          style="
            width: 250px;
            height: 40px;
            background-color: #ec616a;
            line-height: 30px;
            color: white;
          "
          >立即筛选</van-button
        >
      </div>
    </van-popup>

    <van-popup
      style="
        z-index: 9999;
        height: 480px;
        padding: 0px 30px 0px 30px;
        box-sizing: border-box;
      "
      v-model:show="show5"
      round
      closeable
      position="bottom"
    >
      <div class="f1">筛选条件</div>

      <van-grid
        :border="false"
        :column-num="2"
        :gutter="6"
        style="background-color: white; font-size: 12px"
      >
        <van-grid-item @click="clickxb(0)">
          <van-image
            :class="xb == '0' ? 'myimg4' : 'myimg5'"
            width="90"
            height="90"
            :src="require('../assets/gg.jpg')"
          />
          <van-image
            :class="xb == '0' ? 'show' : 'noshow'"
            width="16"
            height="16"
            :src="require('../assets/gou.png')"
          />
          <!--<div>男</div>-->
        </van-grid-item>
        <van-grid-item @click="clickxb(1)">
          <van-image
            :class="xb == '1' ? 'myimg4' : 'myimg5'"
            width="90"
            height="90"
            :src="require('../assets/mm.jpg')"
          />
          <van-image
            :class="xb == '1' ? 'show' : 'noshow'"
            width="16"
            height="16"
            :src="require('../assets/gou.png')"
          />
        </van-grid-item>
      </van-grid>

      <div style="font-size: 18px">婚史</div>
      <van-grid
        :border="false"
        :column-num="3"
        :gutter="6"
        style="
          background-color: white;
          font-size: 12px;
          margin: 20px 0px 20px 0px;
        "
      >
        <van-grid-item
          v-for="(item, index) in hy"
          :key="index"
          @click="clickhy(index)"
        >
          <div :class="item.sel == '0' ? 'hs' : 'hs2'">{{ item.name }}</div>
        </van-grid-item>
      </van-grid>

      <div
        style="
          margin-bottom: 10px;
          font-size: 18px;
          color: #ec616a;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>年龄</div>
        <div>{{ nl[0] }}-{{ nl[1] }}岁</div>
      </div>
      <van-slider v-model="nl" range min="18" max="68" active-color="#ec616a" />
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px 40px 0px;
        "
      >
        <div>18</div>
        <div>28</div>
        <div>38</div>
        <div>48</div>
        <div>58</div>
        <div>68</div>
      </div>

      <div
        style="
          margin-bottom: 10px;
          font-size: 18px;
          color: #ec616a;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>身高</div>
        <div>{{ sg[0] }}-{{ sg[1] }}CM</div>
      </div>
      <van-slider
        v-model="sg"
        range
        min="100"
        max="200"
        active-color="#ec616a"
      />
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px 40px 0px;
        "
      >
        <div>100</div>
        <div>120</div>
        <div>140</div>
        <div>160</div>
        <div>180</div>
        <div>200</div>
      </div>

      <div
        style="
          margin-bottom: 10px;
          font-size: 18px;
          color: #ec616a;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>体重</div>
        <div>{{ tz[0] }}-{{ tz[1] }}KG</div>
      </div>
      <van-slider
        v-model="tz"
        range
        min="40"
        max="120"
        active-color="#ec616a"
      />
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px 40px 0px;
        "
      >
        <div>40</div>
        <div>60</div>
        <div>80</div>
        <div>100</div>
        <div>120</div>
      </div>

      <div style="font-size: 18px">年收入</div>
      <van-grid
        :border="false"
        :column-num="3"
        :gutter="6"
        style="
          background-color: white;
          font-size: 12px;
          margin: 20px 0px 20px 0px;
        "
      >
        <van-grid-item
          v-for="(item, index) in sr"
          :key="index"
          @click="clicksr(index)"
        >
          <div :class="item.sel == '0' ? 'hs' : 'hs2'">{{ item.name }}</div>
        </van-grid-item>
      </van-grid>

      <div style="font-size: 18px">住房</div>
      <van-grid
        :border="false"
        :column-num="3"
        :gutter="6"
        style="
          background-color: white;
          font-size: 12px;
          margin: 20px 0px 20px 0px;
        "
      >
        <van-grid-item
          v-for="(item, index) in house"
          :key="index"
          @click="clickhouse(index)"
        >
          <div :class="item.sel == '0' ? 'hs' : 'hs2'">{{ item.name }}</div>
        </van-grid-item>
      </van-grid>

      <div style="font-size: 18px">学历</div>
      <van-grid
        :border="false"
        :column-num="3"
        :gutter="6"
        style="
          background-color: white;
          font-size: 12px;
          margin: 20px 0px 100px 0px;
        "
      >
        <van-grid-item
          v-for="(item, index) in xl"
          :key="index"
          @click="clickxl(index)"
        >
          <div :class="item.sel == '0' ? 'hs' : 'hs2'">{{ item.name }}</div>
        </van-grid-item>
      </van-grid>

      <div
        style="
          background-color: white;
          width: 100%;
          padding: 10px;
          display: flex;
          justify-content: center;
          position: fixed;
          bottom: 0;
          left: 0;
          border-top: 1px solid #999999;
          z-index: 3;
        "
      >
        <van-button
          @click="resetkey"
          round
          style="
            width: 150px;
            height: 40px;
            background-color: #999999;
            line-height: 30px;
            color: white;
            margin-right: 20px;
          "
          >重置筛选</van-button
        >
        <van-button
          @click="searchbykey"
          round
          style="
            width: 150px;
            height: 40px;
            background-color: #ec616a;
            line-height: 30px;
            color: white;
          "
          >立即筛选</van-button
        >
      </div>
    </van-popup>

    <Footer />
  </div>
</template>

<script>
//import { Notify } from "vant";
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import Footer from "@/components/Footer.vue";
// import Topnav from "@/components/Topnav.vue";

export default {
  name: "Yujian",
  components: {
    Footer,
    // Topnav
  },
  setup() {
    //var blreg = false;

    var xl = reactive([
      {
        name: "不限",
        value: "",
        sel: true,
      },
      {
        name: "小学",
        value: "小学",
        sel: false,
      },
      {
        name: "初中",
        value: "初中",
        sel: false,
      },
      {
        name: "高中",
        value: "高中",
        sel: false,
      },
      {
        name: "大专",
        value: "大专",
        sel: false,
      },
      {
        name: "本科",
        value: "本科",
        sel: false,
      },
      {
        name: "研究生",
        value: "研究生",
        sel: false,
      },
      {
        name: "硕士",
        value: "硕士",
        sel: false,
      },
      {
        name: "博士",
        value: "博士",
        sel: false,
      },
      {
        name: "博士后",
        value: "博士后",
        sel: false,
      },
    ]);
    var house = reactive([
      {
        name: "不限",
        value: "",
        sel: true,
      },
      {
        name: "货款购房",
        value: "货款购房",
        sel: false,
      },
      {
        name: "全款购房",
        value: "全款购房",
        sel: false,
      },
      {
        name: "无房",
        value: "无房",
        sel: false,
      },
    ]);
    var sr = reactive([
      {
        name: "不限",
        value: "",
        sel: true,
      },
      {
        name: "5万以下",
        value: "5万以下",
        sel: false,
      },
      {
        name: "5-8万",
        value: "5-8万",
        sel: false,
      },
      {
        name: "9-15万",
        value: "9-15万",
        sel: false,
      },
      {
        name: "16-25万",
        value: "16-25万",
        sel: false,
      },
      {
        name: "26-50万",
        value: "26-50万",
        sel: false,
      },
      {
        name: "51-100万",
        value: "51-100万",
        sel: false,
      },
      {
        name: "100万以上",
        value: "100万以上",
        sel: false,
      },
    ]);
    var hy = reactive([
      {
        name: "不限",
        value: "",
        sel: true,
      },
      {
        name: "未婚",
        value: "未婚",
        sel: false,
      },
      {
        name: "离异带男孩",
        value: "离异带男孩",
        sel: false,
      },
      {
        name: "离异带女孩",
        value: "离异带女孩",
        sel: false,
      },
      {
        name: "离异未育",
        value: "离异未育",
        sel: false,
      },
      {
        name: "丧偶",
        value: "丧偶",
        sel: false,
      },
    ]);
    // 双滑块模式时，值必须是数组
    const tz = ref([40, 120]);

    const show5 = ref(false);
    const sg = ref([100, 200]);
    const show4 = ref(false);

    const nl = ref([18, 68]);
    const show3 = ref(false);

    const xb = ref("");
    const show2 = ref(false);
    const loading = ref(false);
    const finished = ref(false);

    const show = ref(false);
    var myoption = {
      openid: "",
      pagesize: 6,
      pageindex: 0,

      xb: "", //性别
      age1: "", //年龄
      age2: "",
      sg1: "", //身高
      sg2: "", //身高
      tz1: "", //体重
      tz2: "", //体重
      hy: "", //婚姻
      sr: "", //收入
      house: "", //房子
      xl: "", //学历
    };
    var clicksel = ref("0");
    const { proxy } = getCurrentInstance();
    let mmlist = ref([]);
    var vxcode=ref('');
    onMounted(() => {
      /*let id = proxy.$route.query.userid;
      if (typeof id != "undefined") {
        proxy.GLOBAL.openid = id; //有传过来的，处理下
        Notify("客户id是：" + proxy.GLOBAL.openid);
      }*/
      vxcode.value=localStorage.getItem('vxcode');
      console.log("遇见页面");
      //getorderlist();
    });
    /*const getorderlist = () => {
      myoption.openid = proxy.GLOBAL.openid;
      myoption.type = "0"; //推荐（默认）
      dogetlist();
    };*/
    const sxb = () => {
      show2.value = true;
    };
    const snl = () => {
      show3.value = true;
    };
    const ssg = () => {
      show4.value = true;
    };
    const sall = () => {
      show5.value = true;
    };
    const clickxb = () => {
      if (xb.value == "0") xb.value = 1;
      else xb.value = "0";
      myoption.xb = xb.value;
    };
    const searchbykey = () => {
      finished.value = false;//重置允许检测到底
      myoption.pageindex = 1;
      mmlist.value = [];
      getorderlist();
      show2.value = false;
      show3.value = false;
      show4.value = false;
      show5.value = false;
    };
    const getorderlist = () => {
      /*    var myoption = {
      openid: "",
      pagesize: 6,
      pageindex: 0,

      xb:"",//性别
      age1:"",//年龄
      age2:"",
      sg1:"",//身高
      sg2:"",//身高
      tz1:"",//体重
      tz2:"",//体重
      hy:"",//婚姻
      sr:"",//收入
      house:"",//房子
      xl:""//学历
    }; */
      /* if(type=='1')
    {
           myoption.xb="";
      myoption.age1="";
      myoption.age2="";
      myoption.tz1=""
      myoption.tz2="";
      myoption.hy="";
      myoption.sr="";
      myoption.house="";
      myoption.xl="";
      myoption.pageindex=1;
      mmlist.value=[];
    }
    else{ */

      //myoption.xb = xb.value;
      if (nl.value[0] == "18" && nl.value[1] == "68") {
        myoption.age1 = "";
        myoption.age2 = "";
      } else {
        myoption.age1 = nl.value[0];
        myoption.age2 = nl.value[1];
      }
      if (sg.value[0] == "100" && sg.value[1] == "200") {
        myoption.sg1 = "";
        myoption.sg2 = "";
      } else {
        myoption.sg1 = sg.value[0];
        myoption.sg2 = sg.value[1];
      }
      if (tz.value[0] == "40" && tz.value[1] == "120") {
        myoption.tz1 = "";
        myoption.tz2 = "";
      } else {
        myoption.tz1 = tz.value[0];
        myoption.tz2 = tz.value[1];
      }
      proxy.$http
        .get("https://ttdj.my306.com/zhxq/gethylist.php", {
          params: myoption,
        })
        .then(function (res) {
          console.log(res.data);
          loading.value = false;
          if (res.data.r2 == "0") {
            //console.log('结束了')
            finished.value = true;
          } else mmlist.value = mmlist.value.concat(res.data.r1);
        })
        .catch(function (error) {
          loading.value = false;
          console.log(error);
        });
    };

    const clickme = (index) => {
      console.log("点击了" + index);
      //var tostr = "/userdetail?userid=" + mmlist.value[index].openid;
      //console.log(tostr)
      //proxy.$router.push({ path: '/news', query: { userId: 123 }});

      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({
          path: "/login",
        });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }

      /*proxy.$router.push({
        path: "/userdetail",
        query: { userid: mmlist.value[index].openid },
      });*/
    };
    const clicksel0 = () => {
      clicksel.value = 0; //推荐
      myoption.openid = proxy.GLOBAL.openid;
      myoption.type = "0"; //推荐（默认）
      //dogetlist();
    };
    const clicksel1 = () => {
      clicksel.value = 1; //男生
      myoption.openid = proxy.GLOBAL.openid;
      myoption.type = "1"; //推荐（默认）
      //dogetlist();
    };
    const clicksel2 = () => {
      clicksel.value = 2; //女生
      myoption.openid = proxy.GLOBAL.openid;
      myoption.type = "2"; //推荐（默认）
      //dogetlist();
    };
    const clicksel3 = () => {
      clicksel.value = 3; //匹配
      //myoption.openid = proxy.GLOBAL.openid;
      //myoption.type = "3"; //推荐（默认）
      //dogetlist();
    };
    const moreuser = () => {
      proxy.$router.push({ path: "/yujian" });
    };
    const concat = () => {
      // console.log('点了')
      show.value = true;
    };
    const totop = () => {
      proxy.$refs.target.scrollIntoView();
    };
    const onLoad = () => {
      myoption.pageindex = myoption.pageindex + 1; //触底了下一页
      getorderlist();
      console.log("触底了");
    };
    const search = () => {
      proxy.$router.push({
        path: "/search",
      });
    };
    const party = () => {
      proxy.$router.push({
        path: "/party",
      });
    };
    const clickhy = (index) => {
      for (var i = 0; i < hy.length; i++) hy[i].sel = false;
      hy[index].sel = true;
      myoption.hy = hy[index].value;
    };
    const clicksr = (index) => {
      for (var i = 0; i < sr.length; i++) sr[i].sel = false;
      sr[index].sel = true;
      myoption.sr = sr[index].value;
    };
    const clickhouse = (index) => {
      for (var i = 0; i < house.length; i++) house[i].sel = false;
      house[index].sel = true;
      myoption.house = house[index].value;
    };
    const clickxl = (index) => {
      for (var i = 0; i < xl.length; i++) xl[i].sel = false;
      xl[index].sel = true;
      myoption.xl = xl[index].value;
    };
    const resetkey = () => {
      console.log("重置");
      finished.value = false;//重置允许检测到底

      nl.value[0] = "18";
      nl.value[1] = "68";
      tz.value[0] = "40";
      tz.value[1] = "120";
      sg.value[0] = "100";
      sg.value[1] = "200";

      xb.value = "";
      for (var i = 0; i < hy.length; i++) hy[i].sel = false;
      hy[0].sel = true;
      for (i = 0; i < sr.length; i++) sr[i].sel = false;
      sr[0].sel = true;
      for (i = 0; i < house.length; i++) house[i].sel = false;
      house[0].sel = true;
      for (i = 0; i < xl.length; i++) xl[i].sel = false;
      xl[0].sel = true;

      myoption.xb = "";
      myoption.hy = "";
      myoption.sr = "";
      myoption.house = "";
      myoption.xl = "";
      myoption.pageindex = 1;
      mmlist.value = [];
      getorderlist();
      show2.value = false;
      show3.value = false;
      show4.value = false;
      show5.value = false;
    };
    return {
      resetkey,
      clickxl,
      xl,
      clickhouse,
      house,
      clicksr,
      clickhy,
      onLoad,
      loading,
      finished,
      show,
      totop,
      concat,
      mmlist,
      getorderlist,
      clickme,
      clicksel0,
      clicksel1,
      clicksel2,
      clicksel3,
      clicksel,
      moreuser,
      show2,
      sxb,
      xb,
      clickxb,
      show3,
      snl,
      nl,
      show4,
      ssg,
      sg,
      show5,
      sall,
      search,
      party,
      hy,
      sr,
      tz,
      searchbykey,
      vxcode
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only bk:rgb(245, 245, 245);-->
<style scoped>
.myimg {
  display: inline-block;
}

.myimg2 {
  margin-right: 3px;
}
.myimg3 {
  margin-left: 10px;
}
.myimg4 {
  /*pointer-events: none; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.myimg5 {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(1px);
  /*pointer-events: none; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.show {
  visibility: "";
}
.noshow {
  visibility: hidden;
}
/deep/ .van-grid-item__content {
  padding: 0px;
  border-radius: 5px !important;
}
.bk {
  background-color: white;
}
.bb {
  border: 1px solid #6666;
}
.con1 {
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  padding: 6px 0px 0px 0px;
}
.con2 {
  font-size: 13px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 3px 10px 3px;
  flex-wrap: wrap;
}
.myrow {
  padding: 3px 10px 8px 10px;
  box-sizing: border-box;
  font-size: 15px;
}
.agecolor {
  color: #ec616a;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.sel1 {
  font-weight: 510;
  margin-right: 25px;
  font-size: 22px;
}
.nor1 {
  margin-right: 25px;
  font-size: 18px;
}
.nor2 {
  font-size: 18px;
}
.topbak2 {
  padding: 8px 0px 8px 0px;
}
.zhid {
  font-size: 20px;
}
.shenq {
  font-size: 13px;
  text-align: right;
  color: #ec616a;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.xibao {
  color: #ec616a;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top3 {
  padding: 10px 8px 10px 8px;
  box-sizing: border-box;

  background-color: white;
}
.mybn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 15px 30px;
  background-color: white;
}
.mybn2 {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  color: #ec616a;
  background-color: white;
  padding: 20px 0px 8px 0px;
}
.sel {
  background-color: #ec616a;
  color: white;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 95px;
  border-radius: 18px;
  font-size: 15px;
}
.nor {
  color: black;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 95px;
  border-radius: 18px;
  border: 1px solid rgb(242, 242, 242);
  font-size: 15px;
}
/*.sel1 {
  font-weight: 510;
  margin-right: 10px;
  font-size: 22px;
}
.nor1 {
  margin-right: 10px;
  font-size: 18px;
}*/
.sqzd {
  background-color: white;
  color: #ee7f87;
  text-align: center;
  font-size: 15px;
}
ul {
  display: flex;
  width: 100%;
  height: 172px;
  background: #fff;
  box-sizing: border-box;
  /* 下面是实现横向滚动的关键代码 */
  display: inline;
  float: left;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; /*解决在ios滑动不顺畅问题*/
  overflow-y: hidden;
}
ul li {
  display: inline-block; /*设置li为行内块级元素*/
  width: 110px;
  height: 110px;
  text-align: left;
  border-radius: 6px 6px 6px 6px;
}
.xibaotop {
  padding: 10px 0px 0px 0px;
}
.zhan {
  position: absolute;
  bottom: 75px;
  left: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
}
.myxl {
  margin-right: 10px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  height: 17px;
  line-height: 17px;
  text-align: center;
  /*width: 36px;*/
  padding: 0px 4px 0px 4px;
  border-radius: 3px;
  font-size: 13px;
}
.more {
  width: 110px;
  height: 32px;
  line-height: 32px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  text-align: center;
  margin: 15px auto;
  border-radius: 18px;
  font-size: 14px;
}
.hdtxt {
  font-size: 16px;
  text-align: center;
  margin-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hdcity {
  font-size: 12px;
  color: rgb(120, 120, 120);
  text-align: center;
}
.myxl2 {
  margin-right: 15px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 60px;
  border-radius: 3px;
  font-size: 13px;
}
.concat {
  position: fixed;
  bottom: 150px;
  right: 5px;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}
.totop {
  position: fixed;
  bottom: 90px;
  right: 5px;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 280px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
}
.tiptxt {
  font-size: 12px;
  margin-top: 15px;
}
.tiptxt2 {
  font-size: 12px;
}

.toprow {
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  border-bottom: 1px solid #f7f7f7;
  background-color: white;
  color: #999999;
}
.f1 {
  color: #ec616a;
  font-size: 14px;
  text-align: center;
  margin: 40px 0px 40px 0px;
}
.hs {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  background-color: #f7f7f7;
  text-align: center;
  font-size: 14px;
}
.hs2::before {
  content: "";
  background: url("../assets/gou2.png") no-repeat top left / 16px 16px; /*兼容没测*/
  position: absolute;
  bottom: 0;
  right: 6px;
  z-index: 2;
  width: 16px;
  height: 16px;
}
.hs2 {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  background-color: #f3dcdc;
  color: #ec616a;
  text-align: center;
  font-size: 14px;
}
</style>
