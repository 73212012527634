<template>
  <div class="topcon">
    <!--<van-image
      :src="require('../assets/12196.png')"
      height="110"
      width="100%"
    />-->
    <van-sticky>
      <van-notice-bar
        left-icon="volume-o"
        text="温馨提示：您所填写的所有信息绝对保密，请放心填写，在征得您的同意后才会公开。"
      />
    </van-sticky>

    <div class="row3">
      <div class="mytitle">个人照片</div>
      <div class="row2">（上传真实生活照，默认第一张图片为头像）</div>
      <div class="mypic">
        <van-uploader
          preview-size="95"
          @click-preview="clickpreview"
          :preview-image="true"
          :preview-full-image="false"
          :after-read="afterRead"
          :before-read="beforeRead"
          v-model="fileList"
        />
      </div>
    </div>

    <div class="row1">
      <div class="mytitle">基本信息</div>

      <van-row class="myrow" align="center">
        <van-col span="8">ID</van-col>
        <van-col span="16">{{ id }}</van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*姓名</van-col>
        <van-col span="16">
          <van-field v-model="myname" placeholder="请输入姓名" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*性别</van-col>
        <van-col span="16">
          <van-radio-group v-model="myxb" direction="horizontal">
            <van-radio name="0">男</van-radio>
            <van-radio name="1">女</van-radio>
          </van-radio-group>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*出生日期</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup">{{ getmydate() }}</van-cell>
          <van-popup v-model:show="show" position="bottom">
            <van-datetime-picker
              v-model="mycurrentDate"
              type="date"
              @confirm="onConfirm"
              @cancel="onCancel"
              :min-date="minDate"
              :max-date="maxDate"
            />
          </van-popup>
        </van-col>
      </van-row>
      <van-row class="myrow" align="center">
        <van-col span="8">*身高(CM)</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup2">{{
            mysg == "" ? "请选择" : mysg
          }}</van-cell>
          <van-popup v-model:show="show2" position="bottom">
            <van-picker
              title="请选择身高"
              :columns="columns2"
              @confirm="onConfirm2"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*体重(KG)</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup3">{{
            mytz == "" ? "请选择" : mytz
          }}</van-cell>
          <van-popup v-model:show="show3" position="bottom">
            <van-picker
              title="请选择体重"
              :columns="columns3"
              @confirm="onConfirm3"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">星座</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup4">{{
            myxz == "" ? "请选择" : myxz
          }}</van-cell>
          <van-popup v-model:show="show4" position="bottom">
            <van-picker
              title="请选择星座"
              :columns="columns4"
              @confirm="onConfirm4"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">属相</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup5">{{
            mysx == "" ? "请选择" : mysx
          }}</van-cell>
          <van-popup v-model:show="show5" position="bottom">
            <van-picker
              title="请选择星座"
              :columns="columns5"
              @confirm="onConfirm5"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*婚姻状况</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup6">{{
            myhyzk == "" ? "请选择" : myhyzk
          }}</van-cell>
          <van-popup v-model:show="show6" position="bottom">
            <van-picker
              title="请选择星座"
              :columns="columns6"
              @confirm="onConfirm6"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*手机号</van-col>
        <van-col span="16">
          <van-field v-model="mytel"  placeholder="请输入手机号" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*微信号</van-col>
        <van-col span="16">
          <van-field v-model="mywechat" placeholder="请输入微信号" />
        </van-col>
      </van-row>
      <van-row class="myrow" align="center">
        <van-col span="8">*工作单位</van-col>
        <van-col span="16">
          <van-field v-model="mycompany" placeholder="请输入工作单位" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*职务</van-col>
        <van-col span="16">
          <van-field v-model="myjob" placeholder="请输入职务" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*年收入</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup7">{{
            myshouru == "" ? "请选择" : myshouru
          }}</van-cell>
          <van-popup v-model:show="show7" position="bottom">
            <van-picker
              title="请选择年收入"
              :columns="columns7"
              @confirm="onConfirm7"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">毕业院校</van-col>
        <van-col span="16">
          <van-field v-model="myschool" placeholder="请输入毕业院校" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*学历</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup8">{{
            myxueli == "" ? "请选择" : myxueli
          }}</van-cell>
          <van-popup v-model:show="show8" position="bottom">
            <van-picker
              title="请选择学历"
              :columns="columns8"
              @confirm="onConfirm8"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">兴趣爱好</van-col>
        <van-col span="16">
          <van-field v-model="myah" placeholder="请输入兴趣爱好" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*籍贯</van-col>
        <van-col span="16">
          <van-field v-model="mycity" placeholder="请输入籍贯(如南宁等）" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*现居住地</van-col>
        <van-col span="16">
          <van-field v-model="myadress" placeholder="请输入城市（如南宁等）" />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*是否有房</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup9">{{
            myhouse == "" ? "请选择" : myhouse
          }}</van-cell>
          <van-popup v-model:show="show9" position="bottom">
            <van-picker
              title="请选择是否有房"
              :columns="columns9"
              @confirm="onConfirm9"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*是否有车</van-col>
        <van-col span="16">
          <van-cell is-link @click="showPopup10">{{
            mycar == "" ? "请选择" : mycar
          }}</van-cell>
          <van-popup v-model:show="show10" position="bottom">
            <van-picker
              title="请选择是否有车"
              :columns="columns10"
              @confirm="onConfirm10"
              @cancel="onCancel"
            />
          </van-popup>
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">个人简介</van-col>
        <van-col span="16">
          <van-field
            autosize
            type="textarea"
            v-model="jianjie"
            placeholder="做一个自我介绍，让别人认识你~~"
          />
        </van-col>
      </van-row>
    </div>
    <div class="row1">
      <div class="mytitle">择偶要求</div>

      <van-row class="myrow" align="center">
        <van-col span="8">*择偶要求</van-col>
        <van-col span="16">
          <van-field
            autosize
            type="textarea"
            v-model="myyaoqiu"
            placeholder="对方的身高、年龄、学历、职业、颜值等要求"
          />
        </van-col>
      </van-row>

      <van-row class="myrow" align="center">
        <van-col span="8">*是否介意离异</van-col>
        <van-col span="16">
          <van-radio-group v-model="myjieyi" direction="horizontal">
            <van-radio name="0">介意</van-radio>
            <van-radio name="1">不介意</van-radio>
          </van-radio-group>
        </van-col>
      </van-row>
    </div>
    <van-button type="warning" style="width: 100%" @click="savemyinfo"
      >提交</van-button
    >

    <div class="uploadava">
      <!-- <van-popup
        duration="0"
        v-model:show="showCropper"
        position="top"
        :style="{ height: '100%' }"
      >-->
      <van-dialog
        v-model:show="loadingShow"
        :show-cancel-button="false"
        :showConfirmButton="false"
      >
        <van-loading size="50">上传中... </van-loading>
      </van-dialog>
      <div class="cropper-container" v-show="showCropper">
        <!--<van-loading
          v-show="loadingShow"
          size="50"
          >上传中...
        </van-loading>-->

        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :full="option.full"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :high="option.high"
          :mode="option.mode"
        />
        <van-nav-bar
          left-text="取消"
          right-text="选择"
          @click-left="onClickLeft"
          @click-right="getCropBlob"
          fixed
        />
      </div>
      <!--</van-popup>-->
    </div>
  </div>
</template>

<script>
//import { Notify } from "vant";
import { ref, getCurrentInstance, onMounted, reactive, toRefs } from "vue";
import "vue-cropper/dist/index.css";
import { VueCropper } from "vue-cropper";
//import { Notify } from 'vant';
import { Toast } from "vant";
export default {
  name: "Loginfo",
  components: {
    VueCropper,
  },
  setup() {
    //引用全局变量
    //let imageFileName='';
    let showCropper = ref(false); // 裁剪的弹窗
    let loadingShow = ref(false);
    let option = reactive({
      img: "",
      outputSize: 0.8,
      info: false, // 裁剪框的大小信息
      outputType: "jpeg", // 裁剪生成图片的格式
      canScale: true, // 图片是否允许滚轮缩放
      autoCrop: true, // 是否默认生成截图框
      autoCropWidth: 300 + "px", // 默认生成截图框宽度window.innerWidth -
      autoCropHeight: 300 + "px", // 默认生成截图框高度window.innerWidth -
      high: true, // 是否按照设备的dpr 输出等比例图片
      fixedBox: false, // 固定截图框大小 不允许改变
      fixed: false, // 是否开启截图框宽高固定比例
      fixedNumber: [1, 1], // 截图框的宽高比例
      full: true, // 是否输出原图比例的截图
      canMoveBox: true, // 截图框能否拖动
      original: false, // 上传图片按照原始比例渲染
      centerBox: true, // 截图框是否被限制在图片里面
      infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      mode: "100% auto", // 图片默认渲染方式
      canMove: false,
    });

    const { proxy } = getCurrentInstance();
    const fileList = ref([]);
    let qiniuData = {
      key: "",
      token: "",
    };
    const upload_qiniu_url = "https://upload-z2.qiniup.com";
    const upload_qiniu_addr = "http://gzqy.dayin163.com/";
    let cureditpicid = -1;
    /*const queryinfo = {
      fenlei: "",
      tuijian: "",
      mykey: "",
      pageindex: 1,
      pagesize: 5,
    };*/
    let mybody = reactive({
      id:"",
      imglist: "",
      openid: "",
      userid: "",
      myxb: "0",
      mysg: "",
      mytz: "",
      myxz: "",
      mysx: "",
      myhyzk: "",
      myname: "",
      mytel: "",
      mywechat: "",
      mycompany: "",
      myjob: "",
      myshouru: "",
      myschool: "",
      myxueli: "",
      myah: "",
      mycity: "",
      myadress: "",
      myhouse: "",
      mycar: "",
      myyaoqiu: "",
      myjieyi: "0",
      mycurrentDate: new Date(),
      age: "",
      jianjie: "",
      regtel:"",//注册手机号
      regname:"",//注册名字
      regpass:"",//注册密码
      type:"0"//默认新增0
    });

    //const checked = ref("1");
    //const mycurrentDate = ref(new Date());
    const show = ref(false);
    const show2 = ref(false);
    const show3 = ref(false);
    const show4 = ref(false);
    const show5 = ref(false);
    const show6 = ref(false);
    const show7 = ref(false);
    const show8 = ref(false);
    const show9 = ref(false);
    const show10 = ref(false);
    const columns2 = [
      "147",
      "148",
      "149",
      "150",
      "151",
      "152",
      "153",
      "154",
      "155",
      "156",
      "157",
      "158",
      "159",
      "160",
      "161",
      "162",
      "163",
      "164",
      "165",
      "166",
      "167",
      "168",
      "169",
      "170",
      "171",
      "172",
      "173",
      "174",
      "175",
      "176",
      "177",
      "178",
      "179",
      "180",
      "181",
      "182",
      "183",
      "184",
      "185",
      "186",
      "187",
      "188",
      "189",
      "190",
      "191",
      "192",
      "193",
      "194",
      "195",
    ];
    const columns3 = [
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61",
      "62",
      "63",
      "64",
      "65",
      "66",
      "67",
      "68",
      "69",
      "70",
      "71",
      "72",
      "73",
      "74",
      "75",
      "76",
      "77",
      "78",
      "79",
      "80",
      "81",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
      "89",
      "90",
      "91",
      "92",
      "93",
      "94",
      "95",
      "96",
      "97",
      "98",
      "99",
      "100",
      "101",
      "102",
      "103",
      "104",
      "105",
    ];
    const columns4 = [
      "白羊座（3.21-4.19）",
      "金牛座（4.20-5.20）",
      "双子座（5.21-6.21）",
      "巨蟹座（6.22-7.22）",
      "狮子座（7.23-8.22）",
      "处女座（8.23-9.22）",
      "天秤座（9.23-10.23）",
      "天蝎座（10.24-11.22）",
      "射手座（11.23-12.21）",
      "魔羯座（12.22-1.19）",
      "水瓶座（1.20-2.18）",
      "双鱼座（2.19-3.20）",
    ];
    const columns5 = [
      "鼠",
      "牛",
      "虎",
      "兔",
      "龙",
      "蛇",
      "马",
      "羊",
      "猴",
      "鸡",
      "狗",
      "猪",
    ];

    const columns6 = [
      "未婚",
      "离异带男孩",
      "离异带女孩",
      "离异不带孩子",
      "离异未育",
      "丧偶",
    ];
    const columns7 = [
      "5万以下",
      "5-8万",
      "9-15万",
      "16-25万",
      "26-50万",
      "51-100万",
      "100万以上"
    ];
    const columns8 = [
      "小学",
      "初中",
      "高中",
      "大专",
      "本科",
      "研究生",
      "硕士",
      "博士",
      "博士后",
    ];
    const columns9 = ["货款购房", "全款购房", "无房"];
    const columns10 = ["货款购车", "全款购车", "无车"];
    const onConfirm = (value) => {
      //Toast(`当前值: ${value}`);
      mybody.mycurrentDate = value;
      show.value = false;
    };
    const onConfirm2 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.mysg = value;
      show2.value = false;
    };
    const onConfirm3 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.mytz = value;
      show3.value = false;
    };
    const onConfirm4 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.myxz = value;
      show4.value = false;
    };
    const onConfirm5 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.mysx = value;
      show5.value = false;
    };
    const onConfirm6 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.myhyzk = value;
      show6.value = false;
    };
    const onConfirm7 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.myshouru = value;
      show7.value = false;
    };
    const onConfirm8 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.myxueli = value;
      show8.value = false;
    };
    const onConfirm9 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.myhouse = value;
      show9.value = false;
    };
    const onConfirm10 = (value) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      mybody.mycar = value;
      show10.value = false;
    };
    const onCancel = () => {
      show.value = false;
      show2.value = false;
      show3.value = false;
      show4.value = false;
      show5.value = false;
      show6.value = false;
      show7.value = false;
      show8.value = false;
      show9.value = false;
      show10.value = false;
    };
    /*
    const onChange = (value, index) => {
      Toast(`当前值: ${value}, 当前索引: ${index}`);
    };
    const onCancel = () => Toast("取消");*/
    const showPopup = () => {
      show.value = true;
    };
    const showPopup2 = () => {
      show2.value = true;
    };
    const showPopup3 = () => {
      show3.value = true;
    };
    const showPopup4 = () => {
      show4.value = true;
    };
    const showPopup5 = () => {
      show5.value = true;
    };
    const showPopup6 = () => {
      show6.value = true;
    };
    const showPopup7 = () => {
      show7.value = true;
    };
    const showPopup8 = () => {
      show8.value = true;
    };
    const showPopup9 = () => {
      show9.value = true;
    };
    const showPopup10 = () => {
      show10.value = true;
    };
    const getmydate = () => {
      let myday="";
      let curdate = mybody.mycurrentDate;
      //console.log('日期是：'+curdate)
      //if(isNaN(curdate) && !isNaN(Date.parse(curdate)))
      if(curdate!=null && curdate!="Invalid Date")
      {
          let myyear = curdate.getFullYear();
          let mymon = curdate.getMonth() + 1;
          myday = curdate.getDate();
          myday = myyear + "-" + mymon + "-" + myday;
      }

      // if(curdate==new Date()) myday="请选择";
      return myday;
    };
    const getQiniuToken = () => {
      proxy.$http
        .post("https://ttdj.my306.com/zhxq/bak/GetQiniuToken.php")
        .then((res) => {
          console.log(res);
          qiniuData.token = res.data.uptoken;
        });
    };
    const beforeRead = (file) => {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPNG) {
        Toast("图片只能是 JPG/PNG 格式!");
        //this.$message.error('图片只能是 JPG/PNG 格式!')
        return false;
      }
      if (!isLt2M) {
        Toast("图片大小不能超过 6MB!");
        //this.$message.error('图片大小不能超过 2MB!')
        return false;
      }
      qiniuData.key = "xq_" + Date.now().toString() + file.name;
      showCropper.value = true;
      imageToBase64(file);
      //imageFileName = file.name

      return false;
    };
    const imageToBase64 = (file) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("来渲染了");
        option.img = reader.result;
        console.log(reader);
      };
    };
    const clickpreview = (file, res) => {
      cureditpicid = res.index; //当前的id
      console.log("当前id" + cureditpicid);
      fetch(file.url)
        .then((response) => response.blob())
        .then((res) => {
          //打印res出来的就是blob类型
          var url = file.url;
          var urlArr = url.split("?");
          var k = urlArr[0],
            appU = k.split("/");
          var myfn = appU[appU.length - 1];

          console.log(res);
          qiniuData.key = "xq_" + Date.now().toString() + myfn;
          showCropper.value = true;
          imageToBase64(res);
        });
    };
    const afterRead = (files) => {
      //console.log('准备上传')
      Toast("开始上传");
      let imgList = []; //定义存储file的数组
      //判断files的类型
      if (files instanceof Array) {
        for (let i = 0; i < files.length; i++) {
          imgList = [...imgList, files[i].file];
        }
      } else {
        imgList = [...imgList, files.file];
      }
      //遍历数组
      let data = new FormData();
      data.append("token", qiniuData.token);
      data.append("key", qiniuData.key);
      let icount = imgList.length;
      let istep = 0;
      for (let i = 0; i < icount; i++) {
        /* data.set("file", imgList[i]);
        proxy.$http.post(upload_qiniu_url, data).then((res) => {
          console.log(upload_qiniu_addr + res.data.key);
          istep = istep + 1;
          if (istep == icount) {
            //console.log("上传成功！");
            Toast("上传成功！");
            //上传完成。
          }
        });*/
        istep = istep + 1;
        //Toast(upload_qiniu_url + upload_qiniu_addr);
      }
    };
    const getCropBlob = () => {
      // let formData = new FormData()
      //this.loadingShow = true;
      //console.log(this.option);
      loadingShow.value = true;
      proxy.$refs.cropper.getCropBlob((mydata) => {
        /*FileList.append({
          url:data
        })*/
        //const file = data.blob;
        // formData.append('headFile', data, this.imageFileName)
        // formData.append('id', this.$store.getters.user.userId)
        //遍历数组
        //   const myBlob = new window.Blob([mydata], {type: 'image/jpeg'});
        // const myurl  = window.URL.createObjectURL(myBlob);
        let data = new FormData();
        data.append("token", qiniuData.token);
        data.append("key", qiniuData.key);
        data.set("file", mydata);
        proxy.$http.post(upload_qiniu_url, data).then((res) => {
          console.log(upload_qiniu_addr + res.data.key);
          if (cureditpicid == -1) {
            //新增
            console.log("新增的");
            fileList.value.push({
              url: upload_qiniu_addr + res.data.key,
              isImage: true,
            });
            loadingShow.value = false;
            showCropper.value = false;
          } //修改
          else {
            console.log(cureditpicid);
            //Vue.set(this.classes,1,{id:123,name:'计应171班'})
            //this.$set(this.classes,1,{id:123,name:'计应171班'});
            fileList.value.splice(cureditpicid, 1, {
              url: upload_qiniu_addr + res.data.key,
              isImage: true,
            });
            /*fileList.value[cureditpicid]={
              url: upload_qiniu_addr + res.data.key,
              isImage: true
            }*/

            cureditpicid = -1; //恢复为新增

            loadingShow.value = false;
            showCropper.value = false;
          }

          //istep = istep + 1;
          //if (istep == icount) {
          //console.log("上传成功！");
          //Toast("上传成功！");
          //showCropper.value = false;
          //上传完成。
          //}
        });

        // uploadAva(formData).then((res) => {
        // this.showCropper = false
        //proxy.$emit('update:showAta', false)
        // this.$emit('update:avaUrl', res.data)
        // })
      });
    };
    const onClickLeft = () => {
      //this.loadingShow = false
      loadingShow.value = false;
      showCropper.value = false;
      //this.$parent.showPhotoUploader = false
    };
    const savemyinfo = () => {
      //var that = this
      //mybody.mycurrentDate=mybody.mycurrentDate.format("yyyy-MM-DD");

      if(fileList.value.length==0) {Toast("请至少上传一张个人生活照!");return;}
      else if(mybody.myxb=='') {Toast("请填写姓名!");return;}
      else if(mybody.mysg=='') {Toast("请选择身高!");return;}
      else if(mybody.mytz=='') {Toast("请选择体重!");return;}
      else if(mybody.myhyzk=='') {Toast("请选择婚姻状况!");return;}
      else if(mybody.mytel=='') {Toast("请选择手机号!");return;}
      else if(mybody.mywechat=='') {Toast("请填写微信号!");return;}
      else if(mybody.myjob=='') {Toast("请填写职务!");return;}
      else if(mybody.myshouru=='') {Toast("请选择年收入!");return;}
      else if(mybody.myxueli=='') {Toast("请选择学历 !");return;}
      else if(mybody.mycity=='') {Toast("请填写籍贯城市!");return;}
      else if(mybody.myadress=='') {Toast("请填写居住地城市!");return;}
      else if(mybody.jianjie=='') {Toast("请填写个人简介!");return;}
      else if(mybody.myyaoqiu=='') {Toast("请填写择偶要求!");return;}

      
     

      let mystr = "";
      for (var i = 0; i < fileList.value.length; i++) {
        mystr = mystr + fileList.value[i].url + "|";
      }
      //计算年龄
      //var birthdays = new Date(mybody.cs.replace(/-/g, "/"));
      var birthdays = mybody.mycurrentDate; //出生日期
      var d = new Date();
      mybody.age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      //以上计算年龄
      mybody.imglist = mystr;
      if(mybody.type=='0') //新增的肯定从注册界面来,不是新增，openid已存在即电话号码
        mybody.openid =proxy.GLOBAL.regtel;// proxy.GLOBAL.openid;
         console.log("openid是"+mybody.openid)
      
      mybody.regtel=proxy.GLOBAL.regtel;//电话
      mybody.regname=proxy.GLOBAL.regname;//用户名
      mybody.regpass=proxy.GLOBAL.regpass;//密码
      proxy.$http
        .post("https://ttdj.my306.com/zhxq/savemyinfo.php", mybody)
        .then(function (response) {
          console.log(response.data);
          proxy.$dialog
            .alert({
              message: "信息已提交成功！",
            })
            .then(() => {

              localStorage.setItem('pnxqtel',mybody.openid);//记录下电话
              if(fileList.value.length>0)
              localStorage.setItem('userico',fileList.value[0].url);
              localStorage.setItem('username',mybody.myname);
              proxy.$router.push({ path: "/index" });
              // on close
            });
          //that.lunboData = response.data.r1
          //that.mytotal = response.data.r2
          //that.typelist = response.data.r3
        })
        .catch(() => {});
    };
    const getmyinfo = () => {
      /*
          let mybody = reactive({
      openid:"",
      userid:"",
      myxb: "0",
      mysg: "",
      mytz: "",
      myxz: "",
      mysx: "",
      myhyzk: "",
      myname: "",
      mytel: "",
      mywechat: "",
      mycompany: "",
      myjob: "",
      myshouru: "",
      myschool: "",
      myxueli: "",
      myah: "",
      mycity: "",
      myadress: "",
      myhouse: "",
      mycar: "",
      myyaoqiu: "",
      myjieyi: "0",
      mycurrentDate: new Date(),
    });
    */
      //var that = this
      proxy.$http
        .get("https://ttdj.my306.com/zhxq/getmyinfo.php", {
          params: { openid: localStorage.getItem("pnxqtel") },
        })
        .then(function (response) {
          console.log(response.data);
          mybody.imglist = response.data.r1.imglist;
          var arry = mybody.imglist.split("|");
          for (var i = 0; i < arry.length - 1; i++) {
            fileList.value.push({
              url: arry[i],
              isImage: true,
            });
          }
          mybody.id = parseInt(response.data.r1.id);
          mybody.openid=response.data.r1.openid;
          mybody.myxb = response.data.r1.xb;
          mybody.mysg = response.data.r1.sg;
          mybody.mytz = response.data.r1.tz;
          mybody.myxz = response.data.r1.xz;
          mybody.mysx = response.data.r1.sx;
          mybody.myhyzk = response.data.r1.hy;
          mybody.mytel = response.data.r1.tel;
          mybody.mywechat = response.data.r1.wechat;
          mybody.mycompany = response.data.r1.gs;
          mybody.myjob = response.data.r1.zw;
          mybody.myshouru = response.data.r1.sr;
          mybody.myschool = response.data.r1.byxx;
          mybody.myxueli = response.data.r1.xl;
          mybody.myah = response.data.r1.ah;
          mybody.mycity = response.data.r1.xg;
          mybody.myadress = response.data.r1.adress;
          mybody.myhouse = response.data.r1.house;
          mybody.mycar = response.data.r1.car;
          mybody.myyaoqiu = response.data.r1.yaoqiu;
          mybody.myjieyi = response.data.r1.jieyi;
          mybody.jianjie = response.data.r1.jianjie;

          mybody.myname = response.data.r1.name;
          mybody.mycurrentDate = new Date(response.data.r1.cs);
          mybody.userid = ""; //response.data.r2
          //that.lunboData = response.data.r1
          //that.mytotal = response.data.r2
          //that.typelist = response.data.r3
        })
        .catch(() => {});
    };
    onMounted(() => {
      //Notify("客户id是：" + proxy.GLOBAL.openid);
      //localStorage.getItem("pnxqtel") == null ||
      //localStorage.getItem("pnxqtel") == ""
      mybody.mytel=proxy.GLOBAL.regtel;//自动填写电话
      getQiniuToken(); //获取七牛云
      //console.log('类型是'+proxy.$route.query.type)
      if (proxy.$route.query.type == "1")
      {
        //如果是已经注册过的，就先获取
        mybody.type="1";//修改
        getmyinfo();
      }
    });

    return {
      fileList,
      afterRead,
      beforeRead,
      show,
      show2,
      show3,
      show4,
      show5,
      show6,
      show7,
      show8,
      show9,
      show10,
      showPopup,
      showPopup2,
      showPopup3,
      showPopup4,
      showPopup5,
      showPopup6,
      showPopup7,
      showPopup8,
      showPopup9,
      showPopup10,
      columns2,
      columns3,
      columns4,
      columns5,
      columns6,
      columns7,
      columns8,
      columns9,
      columns10,
      onConfirm,
      onConfirm2,
      onConfirm3,
      onConfirm4,
      onConfirm5,
      onConfirm6,
      onConfirm7,
      onConfirm8,
      onConfirm9,
      onConfirm10,
      getmydate,
      savemyinfo,
      getmyinfo,
      mybody,
      getQiniuToken,
      ...toRefs(mybody),
      showCropper,
      option,
      onClickLeft,
      getCropBlob,
      clickpreview,
      loadingShow,
      onCancel,
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(2000, 10, 1),
    };
  },
};
</script>
<style>
html,
.home {
  background-color: #f7f7f7 !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topcon {
  color: rgba(218, 75, 32, 0.925);
}
.row1 {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.row2 {
  font-size: 13px;
  text-align: center;
}
.row3 {
  font-size: 15px;
  margin-bottom: 20px;
  margin: 0px 10px 0px 10px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.myrow {
  border-top: 1px solid #e4dede;
  padding: 7px 10px 7px 10px;
}
.mytitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.mypic {
  padding: 10px;
}
.cropper-container {
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  width: 100%;
}
.showtxt {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: red;
}
</style>
