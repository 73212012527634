<template>
  <div class="bk">
      <div class="title">基本资料</div>
    <van-cell-group>
      <van-cell title="姓名" :value="mmlist.name" />
      <van-cell title="性别" :value="mmlist.xb=='0'?'男':'女'" />
      <van-cell title="出生日期" :value="mmlist.cs" />
      <van-cell title="身高" :value="mmlist.sg" />
      <van-cell title="体重" :value="mmlist.tz" />
      <van-cell title="星座" :value="mmlist.xz" />
      <van-cell title="属相" :value="mmlist.sx" />
      <van-cell title="婚姻状况" :value="mmlist.hy" />
      <van-cell title="职务" :value="mmlist.zw" />
      <van-cell title="年收入" :value="mmlist.sr" />
      <van-cell title="毕业院校" :value="mmlist.byxx" />
      <van-cell title="学历" :value="mmlist.xl" />
      <van-cell title="兴趣爱好" :value="mmlist.ah" />
      <van-cell title="籍贯" :value="mmlist.xg" />
      <van-cell title="现居住地" :value="mmlist.adress" />
      <van-cell title="是否有房" :value="mmlist.house" />
      <van-cell title="是否有车" :value="mmlist.car" />
      <van-cell title="个人简介" :value="mmlist.jianjie" />
    </van-cell-group>
     <div class="title">择偶要求</div>
     <van-cell title="择偶要求" :value="mmlist.yaoqiu" />
     <van-cell title="是否介意离异" :value="mmlist.jieyi=='0'?'介意':'不介意'" />
  </div>
</template>

<script>
//import { ref } from 'vue';
import { ref,getCurrentInstance, onMounted } from "vue";
export default {
  name: "Moreinfo",
  setup() {
    //const active = ref(0);
    //return { active };
    var mmlist = ref("");
    const { proxy } = getCurrentInstance();
      onMounted(() => {
        //  console.log(proxy.$route.query.userdata);
      mmlist.value = JSON.parse(proxy.$route.query.userdata);
      //console.log("传来的是："+mmlist.value.name)
      /*if (typeof openid != "undefined") {
        // Notify("客户id是：" + proxy.GLOBAL.openid);
      }*/
      //getorderlist();
    });
     /*const getorderlist = () => {
      proxy.$http
        .get("https://ttdj.my306.com/zhxq/getuserinfobyid.php", {
          params: { openid: openid },
        })
        .then(function (res) {
          console.log("来了");
          mmlist.value = res.data.r1;
        
        })
        .catch(function (error) {
          console.log(error);
        });
    };*/
    return{
        //getorderlist,
        mmlist
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk{
    background-color: white;
}
.title{
    margin: 20px 0px 15px 15px;
    font-size: 18px;
    font-weight: 500;
}
</style>
