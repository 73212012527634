<template>
  <div />
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((xq) => {
      xq.$router.replace({
        name: from.name,
        query: to.query
      })
    })
  }
}
</script>
