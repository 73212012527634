<template>
  <div class="content">
        <van-image
            width="180"
            height="180"
            :src="vxcode"
          />
   <div class="f1">请长按上图二维码，加客服微信进行认证，认证通过后客服将从后台设置认证成功。</div> 
  </div>
</template>

<script>
import { ref,onMounted } from 'vue';
export default {
  name: 'errmsg',
  setup () {
    const vxcode = ref('');
    onMounted(() => {
       vxcode.value=localStorage.getItem('vxcode');
    });
    return { vxcode };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
    height: 100vh;
    padding: 100px 50px 0px 50px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center; 
    background-color: white;
}
.f1{
    font-size: 16px;
    margin-top: 20px;
}
</style>
