<template>
  <div class="content">
    请您先关注我们的微信公众号，再从公众号菜单中进入。
     <div class="wrapper">
        <div class="block">
          <van-image
            width="160px"
            height="160px"
            :src="require('../assets/code.jpg')"
          />

          <div class="tiptxt">请扫码关注“龚州情缘"公众号</div>
          <div class="tiptxt2">推荐对象，飞速脱单</div>
        </div>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'errmsg',
  setup () {
    const active = ref(0);
    return { active };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
    padding: 200px 50px 0px 50px;
    box-sizing: border-box;
    font-size: 20px;
    text-align: center;
    
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 280px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
}
.tiptxt {
  font-size: 12px;
  margin-top: 15px;
}
.tiptxt2 {
  font-size: 12px;
}
</style>
