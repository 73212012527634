import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Grid, GridItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Image as VanImage } from 'vant';
import { Divider } from 'vant';
import { Col, Row } from 'vant';
import { Dialog } from 'vant';
import axios from 'axios'
import { NoticeBar } from 'vant';
import { Sticky } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Uploader } from 'vant';
import { Field } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { NavBar } from 'vant';
import { Loading } from 'vant';
import { Tag } from 'vant';
import { ActionBar, ActionBarIcon, ActionBarButton } from 'vant';
import { Icon } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Toast } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Overlay } from 'vant';
import { List } from 'vant';
import { Search } from 'vant';
import { CountDown } from 'vant';
import { Slider } from 'vant';









//axios.defaults.baseURL = 'https://ttdj.my306.com/zhxq/'
//app.prototype.$http = axios
const app = createApp(App);
app.config.globalProperties.$http = axios;
app.use(store).use(router).mount('#app')
app.use(NoticeBar);
app.use(Sticky);
app.use(Col);
app.use(Row);
app.use(Button);
app.use(Divider);
app.use(VanImage);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Grid);
app.use(GridItem);
app.use(Tab);
app.use(Tabs);
app.use(Swipe);
app.use(SwipeItem);
app.use(Dialog);
app.use(Cell);
app.use(CellGroup);
app.use(Uploader);
app.use(Field);
app.use(Radio);
app.use(RadioGroup);
app.use(DatetimePicker);
app.use(Popup);
app.use(Picker);
app.use(Empty);
app.use(NavBar);
app.use(Loading);
app.use(Tag);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(Icon);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Toast);
app.use(Collapse);
app.use(CollapseItem);
app.use(Overlay);
app.use(List);
app.use(Search);
app.use(CountDown);
app.use(Slider);

/*router.beforeRouteLeave( (to, from, next)=> {
  if (from.path=='/yujian' && to.path === '/userdetail') {
    from.meta.keepAlive = true
  } else {
    from.meta.keepAlive = false
   // this.$destroy()
  }
  next()
});*/

router.beforeEach((to, from, next) => {
  
  if(to.path=='/')
  {
    router.replace({path:'/index'});
  }

  if(!/MicroMessenger/i.test(navigator.userAgent) && to.name.indexOf('Errmsg')==-1){
    // window.location.href = '/errmsg';
    return next({path:'/errmsg'});
  }
  
  /* else if(to.name.indexOf('Errmsg')!=-1){
    return;
  } */


  if(from.path=='/userdetail' && to.path=='/yujian'){
    to.meta.keepAlive=true;
    //console.log('来了路由')
    //判断是从哪个路由过来的，
    //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
  }
  else
  {
    to.meta.keepAlive=false;
  }



  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.path=='/myinfo'||to.path=='/buy'){
    if (
      localStorage.getItem("pnxqtel") == null ||
      localStorage.getItem("pnxqtel") == ""
    ) {
      //return next({path:'/login',replace:true});
      //router.replace({path:'/login'});
      return next({path:'/login'});
      //console.log(router)
    }
  }
  next()
})

import global_ from '@/components/Global.vue'
app.config.globalProperties.GLOBAL = global_