<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">实名认证</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <div class="mytxt">身份信息</div>
    <van-cell-group inset>
      <van-field
        ref="user"
        style="font-size: 16px"
        size="large"
        v-model="value1"
        :left-icon="require('../assets/user.png')"
        placeholder="请输入姓名"
      />
      <van-field
        ref="pass"
        style="font-size: 16px; margin-top: 15px"
        v-model="value2"
        clearable
        size="large"
        :left-icon="require('../assets/sfz.png')"
        placeholder="请输入身份证号"
      />
    </van-cell-group>

    <div class="mytxt">请上传身份证图片</div>
    <van-row>
      <van-col span="12" align="center">
        <van-uploader
          :before-read="beforeRead1"
          v-model="fileList1"
          max-count="1"
          preview-size="95"
        />
        <div>身份证人像面</div>
      </van-col>
      <van-col span="12" align="center">
        <van-uploader
          :before-read="beforeRead2"
          v-model="fileList2"
          max-count="1"
          preview-size="95"
        />
        <div>身份证国徽面</div>
      </van-col>
    </van-row>

    <van-row justify="center">
      <van-button
        round
        color="#ec616a"
        style="width: 300px; margin: 50px 0px 30px 0px; font-size: 16px"
        @click="saveit"
        >提交</van-button
      >
    </van-row>
    <!--<van-row justify="center" style="font-size: 14px; margin-bottom: 15px">
     <div @click="findpass"> 忘记密码</div>
    </van-row>
    <van-row justify="center" style="font-size: 14px; padding: 0px 0px 20px 0x">
      <div @click="reg">没有帐号？点击注册</div>
    </van-row>-->
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, toRefs, onMounted } from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "Shiming",
  setup() {
    let qiniuData = {
      key: "",
      token: "",
    };
    const upload_qiniu_url = "https://upload-z2.qiniup.com";
    const upload_qiniu_addr = "https://tutudaojia.runlvo.com/";
    const { proxy } = getCurrentInstance();
    const fileList1 = ref([]);
    const fileList2 = ref([]);
    var mybody = reactive({
      value1: "",
      value2: "",
    });
    onMounted(() => {
      getQiniuToken(); //获取七牛云
    });
    const getQiniuToken = () => {
      proxy.$http
        .post("https://ttdj.my306.com/fxxt/bak/GetQiniuToken.php")
        .then((res) => {
          console.log(res);
          qiniuData.token = res.data.uptoken;
        });
    };
    /*
    const beforeRead = (file) => {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPNG) {
        Toast("图片只能是 JPG/PNG 格式!");
        //this.$message.error('图片只能是 JPG/PNG 格式!')
        return false;
      }
      if (!isLt2M) {
        Toast("图片大小不能超过 6MB!");
        //this.$message.error('图片大小不能超过 2MB!')
        return false;
      }
      qiniuData.key = "xq_" + Date.now().toString() + file.name;
      // showCropper.value = true;
      // imageToBase64(file);
      // imageFileName = file.name

      return true;
    };
    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      console.log("开始上传");
      let data = new FormData();
      data.append("token", qiniuData.token);
      data.append("key", qiniuData.key);
      data.set("file", file);
      proxy.$http.post(upload_qiniu_url, data).then((res) => {
        Toast("上传成功!");
        console.log(upload_qiniu_addr + res.data.key);
        fileList1.value[0] = upload_qiniu_addr + res.data.key;
      });
      // console.log(file);
      // console.log(fileList1.value[0]);
    };
    const afterRead2 = (file) => {
      // 此时可以自行将文件上传至服务器
      let data = new FormData();
      data.append("token", qiniuData.token);
      data.append("key", qiniuData.key);
      data.set("file", file);
      proxy.$http.post(upload_qiniu_url, data).then((res) => {
        Toast("上传成功!");
        console.log(upload_qiniu_addr + res.data.key);
        fileList2.value[0] = upload_qiniu_addr + res.data.key;
      });
      // console.log(file);
      // console.log(fileList2.value);
    };*/
    const beforeRead1 = (file) => {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      // const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPNG) {
        Toast("图片只能是 JPG/PNG 格式!");
        // this.$message.error('图片只能是 JPG/PNG 格式!')
        return false;
      } else {
        qiniuData.key = "xq_" + Date.now().toString() + file.name;
        let data = new FormData();
        data.append("token", qiniuData.token);
        data.append("key", qiniuData.key);
        data.append("file", file);
        console.log(data);
        proxy.$http.post(upload_qiniu_url, data).then((res) => {
          console.log(upload_qiniu_addr + res.data.key);
          fileList1.value = [
            {
              url: upload_qiniu_addr + res.data.key,
              isImage: true,
            },
          ];
          return false;
        });
      }
    };
    const beforeRead2 = (file) => {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      // const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPNG) {
        Toast("图片只能是 JPG/PNG 格式!");
        // this.$message.error('图片只能是 JPG/PNG 格式!')
        return false;
      } else {
        qiniuData.key = "xq_" + Date.now().toString() + file.name;
        let data = new FormData();
        data.append("token", qiniuData.token);
        data.append("key", qiniuData.key);
        data.append("file", file);
        console.log(data);
        proxy.$http.post(upload_qiniu_url, data).then((res) => {
          console.log(upload_qiniu_addr + res.data.key);
          fileList2.value = [
            {
              url: upload_qiniu_addr + res.data.key,
              isImage: true,
            },
          ];
          return false;
        });
      }
    };
    const back = () => {
      proxy.$router.go(-1);
    };

    const saveit = () => {
      console.log("保存了");
      let mydata = {
        openid: "",
        name: "",
        rzinfo: "",
        pic1: "",
        pic2: "",
        type: "",
      };
      if (mybody.value1 == "") {
        Toast("请输入真实姓名!");
        return;
      } else if (mybody.value2 == "") {
        Toast("请输入身份证号码!");
        return;
      } else if (fileList1.value.length == 0) {
        Toast("请上传身份证正面照片！");
        return;
      } else if (fileList2.value.length == 0) {
        Toast("请上传身份证反面照片！");
        return;
      }
      mydata.openid = localStorage.getItem("pnxqtel"); // 电话号码
      mydata.name = mybody.value1;
      mydata.rzinfo = mybody.value2;
      mydata.pic1 = fileList1.value[0].url;
      mydata.pic2 = fileList2.value[0].url;
      mydata.type = "1";
      proxy.$http
        .post("https://ttdj.my306.com/zhxq/saverz.php", mydata)
        .then((res) => {
          console.log(res.data);
          if (res.data.r1 == "ok") {
            Dialog.alert({
              message: "提交成功！",
              theme: "round-button",
            }).then(() => {
              // on close
              proxy.$router.push({
                path: "/index",
              });
            });
            // Toast("提交成功！");
          } else Toast("提交失败！");
          // console.log(upload_qiniu_addr + res.data.key);
        });
    };

    return {
      getQiniuToken,
      beforeRead1,
      beforeRead2,
      saveit,
      fileList1,
      fileList2,
      mybody,
      ...toRefs(mybody),
      back,
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  font-size: 12px;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 18px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
