<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="5">
        </van-col>
        <van-col span="14" style="text-align: center"
          ><span class="sel1">推荐</span
          ><span class="nor1" @click="party">活动</span>
          <span class="nor2" @click="search">搜索</span></van-col
        >
        <van-col span="5"></van-col>
      </van-row>
    </van-sticky>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
export default {
  name: "Login",
  setup() {
    const { proxy } = getCurrentInstance();
    /*const active = ref(0);
    var mybody = reactive({
      value1: "",
      value2: "",
    });*/

    const back = () => {
      proxy.$router.go(-1);
    };
    const search = () => {
      proxy.$router.push({
        path: "/search",
      });
    };
    const party = () => {
      proxy.$router.push({
        path: "/party",
      });
    };
    return { back, search, party };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  background-color: white;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.sel1 {
  font-weight: 510;
  margin-right: 25px;
  font-size: 22px;
}
.nor1 {
  margin-right: 25px;
  font-size: 18px;
}
.nor2 {
  font-size: 18px;
}
</style>
