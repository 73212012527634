<template>
  <div @click.stop>
    <van-tabbar v-model="active" active-color="rgba(237, 120, 116)" route>
      <van-tabbar-item icon="like-o"  to="/index">
        <span>相亲</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o"  to="/yujian">
        <span>会员</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="shop-collect-o"  to="/party">
        <span>活动</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>

      <!-- <van-tabbar-item icon="flag-o"
                       replace
                       to="/party">相亲活动</van-tabbar-item>-->
      <van-tabbar-item icon="user-o"  to="/myinfo">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon4.active : icon4.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Footer",
  setup() {
    const active = ref(0);
    const icon1 = {
      active: require('../assets/index1.png'),
      inactive: require('../assets/index.png'),
    };
    const icon2 = {
      active: require('../assets/hy1.png'),
      inactive: require('../assets/hy.png'),
    };
    const icon3 = {
      active: require('../assets/cz1.png'),
      inactive: require('../assets/cz.png'),
    };
    const icon4 = {
      active: require('../assets/my1.png'),
      inactive: require('../assets/my.png'),
    };
    return { active,icon1,icon2,icon3,icon4 };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
